const playableRounds = `1,1,1,1,1
1,1,1,10,11
1,1,1,10,12
1,1,1,10,13
1,1,1,10,9
1,1,1,2,1
1,1,1,2,2
1,1,1,2,4
1,1,1,2,5
1,1,1,3,1
1,1,1,3,2
1,1,1,3,3
1,1,1,4,1
1,1,1,4,2
1,1,1,4,3
1,1,1,4,4
1,1,1,4,5
1,1,1,4,6
1,1,1,4,7
1,1,1,5,3
1,1,1,5,6
1,1,1,5,7
1,1,1,6,5
1,1,1,6,6
1,1,1,6,7
1,1,1,6,8
1,1,1,6,9
1,1,1,7,10
1,1,1,7,6
1,1,1,7,7
1,1,1,7,9
1,1,1,8,7
1,1,1,8,8
1,1,1,8,9
1,1,1,9,10
1,1,1,9,8
1,1,10,10,1
1,1,10,10,19
1,1,10,10,2
1,1,10,2,1
1,1,10,2,10
1,1,10,2,12
1,1,10,2,13
1,1,10,2,14
1,1,10,2,3
1,1,10,2,4
1,1,10,2,5
1,1,10,2,6
1,1,10,2,7
1,1,10,2,9
1,1,10,3,1
1,1,10,3,10
1,1,10,3,11
1,1,10,3,12
1,1,10,3,13
1,1,10,3,14
1,1,10,3,6
1,1,10,4,1
1,1,10,4,10
1,1,10,4,11
1,1,10,4,12
1,1,10,4,13
1,1,10,4,15
1,1,10,4,3
1,1,10,4,6
1,1,10,4,9
1,1,10,5,15
1,1,10,5,16
1,1,10,5,17
1,1,10,5,2
1,1,10,5,4
1,1,10,5,5
1,1,10,5,7
1,1,10,6,16
1,1,10,6,17
1,1,10,6,18
1,1,10,6,2
1,1,10,6,4
1,1,10,6,6
1,1,10,7,15
1,1,10,7,17
1,1,10,7,18
1,1,10,7,19
1,1,10,7,2
1,1,10,7,4
1,1,10,8,1
1,1,10,8,18
1,1,10,8,19
1,1,10,8,2
1,1,10,8,3
1,1,10,8,4
1,1,10,9,1
1,1,10,9,17
1,1,10,9,19
1,1,10,9,2
1,1,2,2,1
1,1,2,2,2
1,1,2,2,4
1,1,2,2,6
1,1,2,3,2
1,1,2,3,3
1,1,2,3,4
1,1,2,3,5
1,1,2,3,6
1,1,2,4,1
1,1,2,4,16
1,1,2,4,2
1,1,2,4,3
1,1,2,4,4
1,1,2,4,6
1,1,2,5,1
1,1,2,5,10
1,1,2,5,2
1,1,2,5,3
1,1,2,5,5
1,1,2,5,8
1,1,2,5,9
1,1,2,6,10
1,1,2,6,14
1,1,2,6,3
1,1,2,6,4
1,1,2,6,6
1,1,2,6,8
1,1,2,7,1
1,1,2,7,10
1,1,2,7,11
1,1,2,7,14
1,1,2,7,16
1,1,2,7,4
1,1,2,7,5
1,1,2,7,7
1,1,2,7,9
1,1,2,8,1
1,1,2,8,10
1,1,2,8,11
1,1,2,8,12
1,1,2,8,16
1,1,2,8,17
1,1,2,8,2
1,1,2,8,4
1,1,2,8,6
1,1,2,8,7
1,1,2,8,8
1,1,2,9,1
1,1,2,9,11
1,1,2,9,12
1,1,2,9,17
1,1,2,9,18
1,1,2,9,19
1,1,2,9,7
1,1,2,9,8
1,1,2,9,9
1,1,3,3,1
1,1,3,3,3
1,1,3,3,4
1,1,3,3,8
1,1,3,3,9
1,1,3,4,1
1,1,3,4,12
1,1,3,4,2
1,1,3,4,3
1,1,3,4,4
1,1,3,4,5
1,1,3,4,6
1,1,3,4,8
1,1,3,4,9
1,1,3,5,1
1,1,3,5,10
1,1,3,5,16
1,1,3,5,2
1,1,3,5,3
1,1,3,5,4
1,1,3,5,6
1,1,3,5,8
1,1,3,5,9
1,1,3,6,1
1,1,3,6,10
1,1,3,6,11
1,1,3,6,14
1,1,3,6,18
1,1,3,6,3
1,1,3,6,8
1,1,3,6,9
1,1,3,7,10
1,1,3,7,12
1,1,3,7,14
1,1,3,7,16
1,1,3,7,4
1,1,3,7,8
1,1,3,8,1
1,1,3,8,10
1,1,3,8,11
1,1,3,8,12
1,1,3,8,13
1,1,3,8,16
1,1,3,8,2
1,1,3,8,3
1,1,3,8,5
1,1,3,8,6
1,1,3,8,7
1,1,3,8,8
1,1,3,8,9
1,1,3,9,10
1,1,3,9,13
1,1,3,9,14
1,1,3,9,3
1,1,3,9,4
1,1,3,9,5
1,1,3,9,6
1,1,3,9,8
1,1,3,9,9
1,1,4,4,1
1,1,4,4,2
1,1,4,4,3
1,1,4,5,1
1,1,4,5,10
1,1,4,5,11
1,1,4,5,3
1,1,4,5,4
1,1,4,5,5
1,1,4,5,6
1,1,4,5,7
1,1,4,5,8
1,1,4,5,9
1,1,4,6,11
1,1,4,6,12
1,1,4,6,14
1,1,4,6,5
1,1,4,6,6
1,1,4,6,7
1,1,4,6,8
1,1,4,6,9
1,1,4,7,1
1,1,4,7,13
1,1,4,7,14
1,1,4,7,15
1,1,4,7,3
1,1,4,7,4
1,1,4,7,6
1,1,4,7,7
1,1,4,7,8
1,1,4,7,9
1,1,4,8,12
1,1,4,8,13
1,1,4,8,14
1,1,4,8,18
1,1,4,8,2
1,1,4,8,6
1,1,4,8,7
1,1,4,8,8
1,1,4,8,9
1,1,4,9,1
1,1,4,9,10
1,1,4,9,11
1,1,4,9,12
1,1,4,9,15
1,1,4,9,18
1,1,4,9,19
1,1,4,9,2
1,1,4,9,7
1,1,4,9,9
1,1,5,5,1
1,1,5,5,10
1,1,5,5,11
1,1,5,5,12
1,1,5,5,2
1,1,5,6,1
1,1,5,6,11
1,1,5,6,12
1,1,5,6,13
1,1,5,6,2
1,1,5,6,3
1,1,5,7,10
1,1,5,7,11
1,1,5,7,12
1,1,5,7,13
1,1,5,7,14
1,1,5,7,3
1,1,5,7,4
1,1,5,8,1
1,1,5,8,13
1,1,5,8,14
1,1,5,8,15
1,1,5,8,4
1,1,5,8,5
1,1,5,9,12
1,1,5,9,15
1,1,5,9,16
1,1,5,9,2
1,1,5,9,3
1,1,5,9,4
1,1,6,6,1
1,1,6,6,12
1,1,6,6,13
1,1,6,6,2
1,1,6,7,1
1,1,6,7,11
1,1,6,7,13
1,1,6,7,14
1,1,6,7,15
1,1,6,8,1
1,1,6,8,12
1,1,6,8,14
1,1,6,8,15
1,1,6,8,16
1,1,6,9,1
1,1,6,9,15
1,1,6,9,16
1,1,6,9,17
1,1,6,9,3
1,1,7,7,1
1,1,7,7,14
1,1,7,7,15
1,1,7,7,16
1,1,7,7,2
1,1,7,8,13
1,1,7,8,14
1,1,7,8,17
1,1,7,9,16
1,1,7,9,17
1,1,7,9,18
1,1,7,9,2
1,1,7,9,3
1,1,8,8,1
1,1,8,8,16
1,1,8,8,18
1,1,8,8,2
1,1,8,9,1
1,1,8,9,15
1,1,8,9,16
1,1,8,9,17
1,1,8,9,19
1,1,8,9,2
1,1,9,9,1
1,1,9,9,18
1,1,9,9,2
1,10,10,10,10
1,10,10,10,9
1,10,10,2,1
1,10,10,2,19
1,10,10,2,2
1,10,10,2,9
1,10,10,3,1
1,10,10,3,10
1,10,10,3,16
1,10,10,3,19
1,10,10,3,2
1,10,10,3,3
1,10,10,4,1
1,10,10,4,10
1,10,10,4,11
1,10,10,4,4
1,10,10,4,9
1,10,10,5,12
1,10,10,5,4
1,10,10,5,5
1,10,10,5,6
1,10,10,6,12
1,10,10,6,6
1,10,10,6,7
1,10,10,7,14
1,10,10,7,6
1,10,10,7,7
1,10,10,7,8
1,10,10,8,7
1,10,10,8,8
1,10,10,8,9
1,10,10,9,10
1,10,10,9,12
1,10,10,9,9
1,10,2,2,10
1,10,2,2,11
1,10,2,2,15
1,10,2,2,18
1,10,2,2,19
1,10,2,2,2
1,10,2,2,6
1,10,2,2,7
1,10,2,3,11
1,10,2,3,12
1,10,2,3,15
1,10,2,3,16
1,10,2,3,18
1,10,2,3,3
1,10,2,3,4
1,10,2,3,6
1,10,2,3,7
1,10,2,3,9
1,10,2,4,1
1,10,2,4,10
1,10,2,4,11
1,10,2,4,12
1,10,2,4,13
1,10,2,4,14
1,10,2,4,15
1,10,2,4,17
1,10,2,4,3
1,10,2,4,7
1,10,2,4,8
1,10,2,4,9
1,10,2,5,1
1,10,2,5,11
1,10,2,5,12
1,10,2,5,17
1,10,2,5,18
1,10,2,5,2
1,10,2,5,3
1,10,2,5,4
1,10,2,5,5
1,10,2,5,6
1,10,2,5,7
1,10,2,6,1
1,10,2,6,11
1,10,2,6,12
1,10,2,6,13
1,10,2,6,15
1,10,2,6,17
1,10,2,6,18
1,10,2,6,19
1,10,2,6,2
1,10,2,6,8
1,10,2,7,1
1,10,2,7,11
1,10,2,7,12
1,10,2,7,13
1,10,2,7,14
1,10,2,7,16
1,10,2,7,19
1,10,2,7,3
1,10,2,7,7
1,10,2,8,1
1,10,2,8,11
1,10,2,8,12
1,10,2,8,13
1,10,2,8,14
1,10,2,8,15
1,10,2,8,17
1,10,2,8,19
1,10,2,8,4
1,10,2,8,5
1,10,2,8,6
1,10,2,8,9
1,10,2,9,10
1,10,2,9,12
1,10,2,9,14
1,10,2,9,16
1,10,2,9,17
1,10,2,9,4
1,10,3,3,11
1,10,3,3,17
1,10,3,3,4
1,10,3,3,7
1,10,3,3,9
1,10,3,4,1
1,10,3,4,10
1,10,3,4,11
1,10,3,4,12
1,10,3,4,13
1,10,3,4,16
1,10,3,4,3
1,10,3,4,4
1,10,3,4,5
1,10,3,4,7
1,10,3,4,9
1,10,3,5,11
1,10,3,5,12
1,10,3,5,13
1,10,3,5,15
1,10,3,5,16
1,10,3,5,17
1,10,3,5,18
1,10,3,5,19
1,10,3,5,2
1,10,3,5,3
1,10,3,5,5
1,10,3,5,9
1,10,3,6,1
1,10,3,6,10
1,10,3,6,13
1,10,3,6,14
1,10,3,6,16
1,10,3,6,17
1,10,3,6,18
1,10,3,6,4
1,10,3,6,5
1,10,3,6,6
1,10,3,6,9
1,10,3,7,1
1,10,3,7,10
1,10,3,7,13
1,10,3,7,14
1,10,3,7,15
1,10,3,7,17
1,10,3,7,18
1,10,3,7,19
1,10,3,7,7
1,10,3,7,8
1,10,3,7,9
1,10,3,8,10
1,10,3,8,11
1,10,3,8,14
1,10,3,8,16
1,10,3,8,18
1,10,3,8,2
1,10,3,8,6
1,10,3,8,8
1,10,3,9,11
1,10,3,9,13
1,10,3,9,16
1,10,3,9,19
1,10,3,9,5
1,10,3,9,7
1,10,3,9,9
1,10,4,4,10
1,10,4,4,11
1,10,4,4,13
1,10,4,4,9
1,10,4,5,1
1,10,4,5,10
1,10,4,5,11
1,10,4,5,12
1,10,4,5,13
1,10,4,5,15
1,10,4,5,17
1,10,4,5,19
1,10,4,5,3
1,10,4,5,5
1,10,4,5,7
1,10,4,5,8
1,10,4,6,1
1,10,4,6,10
1,10,4,6,11
1,10,4,6,3
1,10,4,6,4
1,10,4,6,5
1,10,4,6,6
1,10,4,6,7
1,10,4,7,10
1,10,4,7,11
1,10,4,7,12
1,10,4,7,14
1,10,4,7,17
1,10,4,7,19
1,10,4,7,2
1,10,4,7,3
1,10,4,7,4
1,10,4,7,5
1,10,4,7,7
1,10,4,7,8
1,10,4,8,10
1,10,4,8,11
1,10,4,8,15
1,10,4,8,18
1,10,4,8,19
1,10,4,8,5
1,10,4,8,8
1,10,4,8,9
1,10,4,9,10
1,10,4,9,11
1,10,4,9,12
1,10,4,9,19
1,10,4,9,2
1,10,4,9,5
1,10,4,9,7
1,10,4,9,8
1,10,5,5,1
1,10,5,5,10
1,10,5,5,11
1,10,5,5,8
1,10,5,5,9
1,10,5,6,1
1,10,5,6,10
1,10,5,6,12
1,10,5,6,3
1,10,5,6,8
1,10,5,6,9
1,10,5,7,1
1,10,5,7,11
1,10,5,7,12
1,10,5,7,13
1,10,5,7,14
1,10,5,7,2
1,10,5,7,3
1,10,5,7,7
1,10,5,7,8
1,10,5,7,9
1,10,5,8,10
1,10,5,8,16
1,10,5,8,4
1,10,5,8,8
1,10,5,8,9
1,10,5,9,13
1,10,5,9,14
1,10,5,9,15
1,10,5,9,18
1,10,5,9,3
1,10,5,9,4
1,10,5,9,5
1,10,5,9,6
1,10,5,9,7
1,10,6,6,10
1,10,6,6,11
1,10,6,6,2
1,10,6,6,3
1,10,6,7,10
1,10,6,7,11
1,10,6,7,4
1,10,6,7,8
1,10,6,7,9
1,10,6,8,12
1,10,6,8,3
1,10,6,8,5
1,10,6,9,14
1,10,6,9,15
1,10,6,9,6
1,10,6,9,7
1,10,7,7,10
1,10,7,7,11
1,10,7,8,10
1,10,7,8,12
1,10,7,8,4
1,10,7,8,5
1,10,7,8,8
1,10,7,8,9
1,10,7,9,13
1,10,7,9,7
1,10,7,9,9
1,10,8,8,10
1,10,8,8,11
1,10,8,8,12
1,10,8,8,5
1,10,8,8,9
1,10,8,9,10
1,10,8,9,7
1,10,8,9,8
1,10,8,9,9
1,10,9,9,10
1,10,9,9,11
1,10,9,9,12
1,10,9,9,9
1,2,2,2,2
1,2,2,2,3
1,2,2,2,6
1,2,2,3,1
1,2,2,3,2
1,2,2,3,3
1,2,2,3,4
1,2,2,3,5
1,2,2,3,7
1,2,2,3,8
1,2,2,4,1
1,2,2,4,10
1,2,2,4,12
1,2,2,4,2
1,2,2,4,3
1,2,2,4,4
1,2,2,4,5
1,2,2,4,8
1,2,2,4,9
1,2,2,5,10
1,2,2,5,12
1,2,2,5,4
1,2,2,5,5
1,2,2,5,7
1,2,2,5,8
1,2,2,6,1
1,2,2,6,11
1,2,2,6,13
1,2,2,6,18
1,2,2,6,3
1,2,2,6,4
1,2,2,6,5
1,2,2,6,6
1,2,2,6,7
1,2,2,7,10
1,2,2,7,12
1,2,2,7,14
1,2,2,7,16
1,2,2,7,17
1,2,2,7,2
1,2,2,7,6
1,2,2,7,7
1,2,2,7,9
1,2,2,8,12
1,2,2,8,13
1,2,2,8,14
1,2,2,8,17
1,2,2,8,18
1,2,2,8,19
1,2,2,8,5
1,2,2,9,10
1,2,2,9,12
1,2,2,9,14
1,2,2,9,2
1,2,2,9,5
1,2,2,9,6
1,2,3,3,1
1,2,3,3,12
1,2,3,3,18
1,2,3,3,2
1,2,3,3,3
1,2,3,3,4
1,2,3,3,7
1,2,3,4,1
1,2,3,4,11
1,2,3,4,12
1,2,3,4,15
1,2,3,4,16
1,2,3,4,2
1,2,3,4,4
1,2,3,4,5
1,2,3,4,6
1,2,3,4,7
1,2,3,4,8
1,2,3,4,9
1,2,3,5,10
1,2,3,5,11
1,2,3,5,12
1,2,3,5,14
1,2,3,5,2
1,2,3,5,3
1,2,3,5,4
1,2,3,5,5
1,2,3,5,7
1,2,3,5,9
1,2,3,6,1
1,2,3,6,10
1,2,3,6,11
1,2,3,6,12
1,2,3,6,13
1,2,3,6,15
1,2,3,6,16
1,2,3,6,18
1,2,3,6,2
1,2,3,6,3
1,2,3,6,4
1,2,3,6,5
1,2,3,6,6
1,2,3,6,7
1,2,3,6,9
1,2,3,7,1
1,2,3,7,11
1,2,3,7,12
1,2,3,7,13
1,2,3,7,14
1,2,3,7,16
1,2,3,7,17
1,2,3,7,2
1,2,3,7,3
1,2,3,7,4
1,2,3,7,5
1,2,3,7,7
1,2,3,7,8
1,2,3,8,1
1,2,3,8,11
1,2,3,8,12
1,2,3,8,13
1,2,3,8,14
1,2,3,8,16
1,2,3,8,18
1,2,3,8,19
1,2,3,8,4
1,2,3,8,5
1,2,3,8,6
1,2,3,8,7
1,2,3,8,8
1,2,3,8,9
1,2,3,9,10
1,2,3,9,11
1,2,3,9,12
1,2,3,9,13
1,2,3,9,14
1,2,3,9,15
1,2,3,9,16
1,2,3,9,19
1,2,3,9,2
1,2,3,9,3
1,2,3,9,4
1,2,3,9,5
1,2,3,9,6
1,2,3,9,7
1,2,3,9,9
1,2,4,4,11
1,2,4,4,2
1,2,4,4,3
1,2,4,4,4
1,2,4,4,5
1,2,4,4,6
1,2,4,4,7
1,2,4,5,1
1,2,4,5,10
1,2,4,5,11
1,2,4,5,12
1,2,4,5,13
1,2,4,5,14
1,2,4,5,2
1,2,4,5,3
1,2,4,5,5
1,2,4,5,6
1,2,4,5,7
1,2,4,5,8
1,2,4,5,9
1,2,4,6,1
1,2,4,6,10
1,2,4,6,11
1,2,4,6,13
1,2,4,6,16
1,2,4,6,18
1,2,4,6,2
1,2,4,6,3
1,2,4,6,4
1,2,4,6,5
1,2,4,6,6
1,2,4,6,7
1,2,4,6,8
1,2,4,7,1
1,2,4,7,10
1,2,4,7,11
1,2,4,7,12
1,2,4,7,13
1,2,4,7,14
1,2,4,7,16
1,2,4,7,19
1,2,4,7,2
1,2,4,7,3
1,2,4,7,4
1,2,4,7,6
1,2,4,7,7
1,2,4,7,9
1,2,4,8,1
1,2,4,8,10
1,2,4,8,11
1,2,4,8,12
1,2,4,8,13
1,2,4,8,14
1,2,4,8,15
1,2,4,8,16
1,2,4,8,18
1,2,4,8,19
1,2,4,8,2
1,2,4,8,3
1,2,4,8,4
1,2,4,8,5
1,2,4,8,6
1,2,4,8,8
1,2,4,8,9
1,2,4,9,1
1,2,4,9,11
1,2,4,9,12
1,2,4,9,13
1,2,4,9,14
1,2,4,9,16
1,2,4,9,18
1,2,4,9,2
1,2,4,9,4
1,2,4,9,6
1,2,4,9,7
1,2,4,9,8
1,2,5,5,12
1,2,5,5,13
1,2,5,5,15
1,2,5,5,16
1,2,5,5,2
1,2,5,5,3
1,2,5,5,4
1,2,5,5,9
1,2,5,6,1
1,2,5,6,10
1,2,5,6,13
1,2,5,6,14
1,2,5,6,15
1,2,5,6,16
1,2,5,6,17
1,2,5,6,18
1,2,5,6,2
1,2,5,6,4
1,2,5,6,6
1,2,5,6,7
1,2,5,6,8
1,2,5,6,9
1,2,5,7,1
1,2,5,7,11
1,2,5,7,13
1,2,5,7,14
1,2,5,7,15
1,2,5,7,17
1,2,5,7,18
1,2,5,7,2
1,2,5,7,4
1,2,5,7,5
1,2,5,7,9
1,2,5,8,10
1,2,5,8,11
1,2,5,8,12
1,2,5,8,14
1,2,5,8,15
1,2,5,8,16
1,2,5,8,2
1,2,5,8,3
1,2,5,8,4
1,2,5,8,5
1,2,5,8,6
1,2,5,9,1
1,2,5,9,11
1,2,5,9,13
1,2,5,9,16
1,2,5,9,17
1,2,5,9,19
1,2,5,9,3
1,2,5,9,5
1,2,5,9,6
1,2,5,9,7
1,2,6,6,10
1,2,6,6,11
1,2,6,6,13
1,2,6,6,15
1,2,6,6,19
1,2,6,6,2
1,2,6,6,3
1,2,6,6,6
1,2,6,7,1
1,2,6,7,10
1,2,6,7,12
1,2,6,7,14
1,2,6,7,16
1,2,6,7,2
1,2,6,7,4
1,2,6,7,6
1,2,6,7,7
1,2,6,7,9
1,2,6,8,1
1,2,6,8,10
1,2,6,8,11
1,2,6,8,12
1,2,6,8,13
1,2,6,8,14
1,2,6,8,17
1,2,6,8,3
1,2,6,8,4
1,2,6,8,6
1,2,6,9,1
1,2,6,9,10
1,2,6,9,11
1,2,6,9,12
1,2,6,9,13
1,2,6,9,17
1,2,6,9,18
1,2,6,9,2
1,2,6,9,4
1,2,6,9,5
1,2,6,9,6
1,2,6,9,7
1,2,7,7,11
1,2,7,7,17
1,2,7,7,2
1,2,7,7,3
1,2,7,7,7
1,2,7,8,1
1,2,7,8,12
1,2,7,8,17
1,2,7,8,18
1,2,7,8,2
1,2,7,8,3
1,2,7,8,5
1,2,7,8,8
1,2,7,9,1
1,2,7,9,12
1,2,7,9,13
1,2,7,9,17
1,2,7,9,18
1,2,7,9,19
1,2,7,9,2
1,2,7,9,4
1,2,7,9,5
1,2,7,9,6
1,2,7,9,9
1,2,8,8,1
1,2,8,8,12
1,2,8,8,15
1,2,8,8,17
1,2,8,8,18
1,2,8,8,19
1,2,8,8,2
1,2,8,8,3
1,2,8,8,7
1,2,8,8,9
1,2,8,9,10
1,2,8,9,13
1,2,8,9,18
1,2,8,9,19
1,2,8,9,2
1,2,8,9,4
1,2,8,9,9
1,2,9,9,17
1,2,9,9,2
1,3,3,3,3
1,3,3,3,4
1,3,3,4,1
1,3,3,4,10
1,3,3,4,11
1,3,3,4,2
1,3,3,4,4
1,3,3,4,5
1,3,3,4,6
1,3,3,4,8
1,3,3,5,10
1,3,3,5,12
1,3,3,5,16
1,3,3,5,2
1,3,3,5,3
1,3,3,5,5
1,3,3,5,6
1,3,3,5,7
1,3,3,5,8
1,3,3,6,10
1,3,3,6,12
1,3,3,6,13
1,3,3,6,16
1,3,3,6,2
1,3,3,6,5
1,3,3,6,6
1,3,3,6,7
1,3,3,6,8
1,3,3,7,10
1,3,3,7,12
1,3,3,7,17
1,3,3,7,7
1,3,3,7,8
1,3,3,7,9
1,3,3,8,10
1,3,3,8,11
1,3,3,8,13
1,3,3,8,15
1,3,3,8,18
1,3,3,8,3
1,3,3,8,5
1,3,3,8,6
1,3,3,8,8
1,3,3,9,10
1,3,3,9,13
1,3,3,9,14
1,3,3,9,16
1,3,3,9,18
1,3,3,9,19
1,3,3,9,3
1,3,3,9,4
1,3,4,4,1
1,3,4,4,10
1,3,4,4,16
1,3,4,4,17
1,3,4,4,18
1,3,4,4,2
1,3,4,4,3
1,3,4,4,4
1,3,4,4,8
1,3,4,4,9
1,3,4,5,1
1,3,4,5,10
1,3,4,5,11
1,3,4,5,12
1,3,4,5,13
1,3,4,5,15
1,3,4,5,2
1,3,4,5,4
1,3,4,5,5
1,3,4,5,6
1,3,4,5,7
1,3,4,5,8
1,3,4,6,1
1,3,4,6,10
1,3,4,6,12
1,3,4,6,13
1,3,4,6,14
1,3,4,6,17
1,3,4,6,2
1,3,4,6,3
1,3,4,6,5
1,3,4,6,6
1,3,4,6,7
1,3,4,6,8
1,3,4,6,9
1,3,4,7,1
1,3,4,7,10
1,3,4,7,11
1,3,4,7,12
1,3,4,7,13
1,3,4,7,15
1,3,4,7,17
1,3,4,7,18
1,3,4,7,19
1,3,4,7,2
1,3,4,7,5
1,3,4,7,8
1,3,4,7,9
1,3,4,8,11
1,3,4,8,16
1,3,4,8,17
1,3,4,8,18
1,3,4,8,2
1,3,4,8,3
1,3,4,8,4
1,3,4,8,5
1,3,4,8,7
1,3,4,8,8
1,3,4,8,9
1,3,4,9,1
1,3,4,9,10
1,3,4,9,11
1,3,4,9,12
1,3,4,9,13
1,3,4,9,16
1,3,4,9,18
1,3,4,9,19
1,3,4,9,3
1,3,4,9,4
1,3,4,9,5
1,3,4,9,7
1,3,4,9,8
1,3,4,9,9
1,3,5,5,1
1,3,5,5,14
1,3,5,5,2
1,3,5,5,3
1,3,5,5,4
1,3,5,5,5
1,3,5,5,6
1,3,5,6,1
1,3,5,6,10
1,3,5,6,11
1,3,5,6,13
1,3,5,6,14
1,3,5,6,15
1,3,5,6,16
1,3,5,6,17
1,3,5,6,2
1,3,5,6,3
1,3,5,6,4
1,3,5,6,5
1,3,5,6,8
1,3,5,6,9
1,3,5,7,10
1,3,5,7,12
1,3,5,7,13
1,3,5,7,14
1,3,5,7,15
1,3,5,7,16
1,3,5,7,18
1,3,5,7,4
1,3,5,7,5
1,3,5,7,6
1,3,5,7,9
1,3,5,8,11
1,3,5,8,16
1,3,5,8,17
1,3,5,8,18
1,3,5,8,3
1,3,5,8,5
1,3,5,8,6
1,3,5,8,8
1,3,5,8,9
1,3,5,9,10
1,3,5,9,12
1,3,5,9,15
1,3,5,9,16
1,3,5,9,18
1,3,5,9,2
1,3,5,9,3
1,3,5,9,4
1,3,5,9,5
1,3,5,9,6
1,3,5,9,8
1,3,5,9,9
1,3,6,6,1
1,3,6,6,14
1,3,6,6,16
1,3,6,6,18
1,3,6,6,19
1,3,6,6,3
1,3,6,6,4
1,3,6,6,6
1,3,6,6,7
1,3,6,7,1
1,3,6,7,10
1,3,6,7,13
1,3,6,7,14
1,3,6,7,15
1,3,6,7,16
1,3,6,7,17
1,3,6,7,19
1,3,6,7,5
1,3,6,7,6
1,3,6,7,7
1,3,6,8,10
1,3,6,8,12
1,3,6,8,14
1,3,6,8,16
1,3,6,8,17
1,3,6,8,18
1,3,6,8,19
1,3,6,8,3
1,3,6,8,4
1,3,6,8,5
1,3,6,8,6
1,3,6,8,7
1,3,6,8,8
1,3,6,8,9
1,3,6,9,1
1,3,6,9,11
1,3,6,9,12
1,3,6,9,17
1,3,6,9,19
1,3,6,9,3
1,3,6,9,5
1,3,6,9,6
1,3,6,9,8
1,3,6,9,9
1,3,7,7,1
1,3,7,7,12
1,3,7,7,16
1,3,7,7,17
1,3,7,7,18
1,3,7,7,2
1,3,7,7,5
1,3,7,7,6
1,3,7,7,7
1,3,7,7,8
1,3,7,7,9
1,3,7,8,1
1,3,7,8,12
1,3,7,8,13
1,3,7,8,16
1,3,7,8,17
1,3,7,8,18
1,3,7,8,19
1,3,7,8,2
1,3,7,8,5
1,3,7,8,7
1,3,7,8,8
1,3,7,8,9
1,3,7,9,1
1,3,7,9,10
1,3,7,9,12
1,3,7,9,14
1,3,7,9,16
1,3,7,9,17
1,3,7,9,18
1,3,7,9,19
1,3,7,9,2
1,3,7,9,6
1,3,7,9,7
1,3,7,9,8
1,3,7,9,9
1,3,8,8,16
1,3,8,8,17
1,3,8,8,19
1,3,8,8,2
1,3,8,8,4
1,3,8,8,5
1,3,8,8,7
1,3,8,8,8
1,3,8,8,9
1,3,8,9,10
1,3,8,9,13
1,3,8,9,15
1,3,8,9,17
1,3,8,9,18
1,3,8,9,19
1,3,8,9,2
1,3,8,9,3
1,3,8,9,5
1,3,8,9,8
1,3,8,9,9
1,3,9,9,1
1,3,9,9,10
1,3,9,9,11
1,3,9,9,16
1,3,9,9,2
1,3,9,9,3
1,3,9,9,4
1,4,4,4,13
1,4,4,4,4
1,4,4,4,5
1,4,4,4,6
1,4,4,5,14
1,4,4,5,2
1,4,4,5,4
1,4,4,5,5
1,4,4,5,6
1,4,4,5,7
1,4,4,6,15
1,4,4,6,17
1,4,4,6,19
1,4,4,6,5
1,4,4,6,6
1,4,4,6,7
1,4,4,6,8
1,4,4,7,14
1,4,4,7,2
1,4,4,7,6
1,4,4,7,7
1,4,4,7,8
1,4,4,7,9
1,4,4,8,1
1,4,4,8,10
1,4,4,8,15
1,4,4,8,17
1,4,4,8,6
1,4,4,8,7
1,4,4,8,8
1,4,4,8,9
1,4,4,9,10
1,4,4,9,12
1,4,4,9,7
1,4,4,9,8
1,4,4,9,9
1,4,5,5,1
1,4,5,5,12
1,4,5,5,13
1,4,5,5,15
1,4,5,5,16
1,4,5,5,2
1,4,5,5,4
1,4,5,5,5
1,4,5,5,6
1,4,5,5,7
1,4,5,6,10
1,4,5,6,11
1,4,5,6,13
1,4,5,6,14
1,4,5,6,15
1,4,5,6,16
1,4,5,6,18
1,4,5,6,2
1,4,5,6,5
1,4,5,6,6
1,4,5,6,7
1,4,5,6,8
1,4,5,7,1
1,4,5,7,10
1,4,5,7,12
1,4,5,7,13
1,4,5,7,14
1,4,5,7,16
1,4,5,7,17
1,4,5,7,18
1,4,5,7,2
1,4,5,7,4
1,4,5,7,6
1,4,5,7,7
1,4,5,7,8
1,4,5,7,9
1,4,5,8,13
1,4,5,8,14
1,4,5,8,18
1,4,5,8,19
1,4,5,8,3
1,4,5,8,4
1,4,5,8,5
1,4,5,8,6
1,4,5,8,7
1,4,5,8,8
1,4,5,8,9
1,4,5,9,11
1,4,5,9,12
1,4,5,9,14
1,4,5,9,15
1,4,5,9,16
1,4,5,9,18
1,4,5,9,19
1,4,5,9,4
1,4,5,9,9
1,4,6,6,1
1,4,6,6,13
1,4,6,6,14
1,4,6,6,18
1,4,6,6,19
1,4,6,6,2
1,4,6,6,4
1,4,6,6,5
1,4,6,6,6
1,4,6,6,7
1,4,6,6,8
1,4,6,6,9
1,4,6,7,1
1,4,6,7,10
1,4,6,7,13
1,4,6,7,14
1,4,6,7,15
1,4,6,7,18
1,4,6,7,19
1,4,6,7,2
1,4,6,7,5
1,4,6,7,6
1,4,6,7,7
1,4,6,7,8
1,4,6,8,10
1,4,6,8,12
1,4,6,8,14
1,4,6,8,15
1,4,6,8,18
1,4,6,8,19
1,4,6,8,3
1,4,6,8,5
1,4,6,8,6
1,4,6,8,7
1,4,6,8,8
1,4,6,9,11
1,4,6,9,15
1,4,6,9,16
1,4,6,9,18
1,4,6,9,19
1,4,6,9,2
1,4,6,9,3
1,4,6,9,5
1,4,6,9,6
1,4,6,9,7
1,4,6,9,8
1,4,6,9,9
1,4,7,7,1
1,4,7,7,10
1,4,7,7,14
1,4,7,7,17
1,4,7,7,18
1,4,7,7,6
1,4,7,7,7
1,4,7,7,8
1,4,7,7,9
1,4,7,8,1
1,4,7,8,12
1,4,7,8,13
1,4,7,8,15
1,4,7,8,16
1,4,7,8,17
1,4,7,8,19
1,4,7,8,2
1,4,7,8,5
1,4,7,8,6
1,4,7,8,7
1,4,7,8,8
1,4,7,8,9
1,4,7,9,1
1,4,7,9,10
1,4,7,9,11
1,4,7,9,14
1,4,7,9,16
1,4,7,9,17
1,4,7,9,18
1,4,7,9,2
1,4,7,9,8
1,4,8,8,10
1,4,8,8,13
1,4,8,8,17
1,4,8,8,2
1,4,8,8,6
1,4,8,8,7
1,4,8,8,9
1,4,8,9,10
1,4,8,9,15
1,4,8,9,17
1,4,8,9,18
1,4,8,9,19
1,4,8,9,2
1,4,8,9,6
1,4,8,9,7
1,4,8,9,8
1,4,8,9,9
1,4,9,9,1
1,4,9,9,10
1,4,9,9,11
1,4,9,9,16
1,4,9,9,18
1,4,9,9,2
1,4,9,9,4
1,5,5,5,2
1,5,5,5,5
1,5,5,5,6
1,5,5,6,17
1,5,5,6,5
1,5,5,6,6
1,5,5,6,7
1,5,5,7,16
1,5,5,7,17
1,5,5,7,18
1,5,5,7,4
1,5,5,7,6
1,5,5,7,7
1,5,5,7,8
1,5,5,8,17
1,5,5,8,19
1,5,5,8,2
1,5,5,8,9
1,5,5,9,1
1,5,5,9,10
1,5,5,9,19
1,5,5,9,9
1,5,6,6,18
1,5,6,6,4
1,5,6,6,5
1,5,6,6,6
1,5,6,6,7
1,5,6,7,17
1,5,6,7,18
1,5,6,7,19
1,5,6,7,3
1,5,6,7,5
1,5,6,7,6
1,5,6,7,8
1,5,6,7,9
1,5,6,8,19
1,5,6,8,3
1,5,6,8,4
1,5,6,8,8
1,5,6,9,11
1,5,6,9,19
1,5,6,9,2
1,5,6,9,3
1,5,6,9,7
1,5,6,9,8
1,5,6,9,9
1,5,7,7,18
1,5,7,7,5
1,5,7,7,6
1,5,7,7,8
1,5,7,8,11
1,5,7,8,3
1,5,7,8,4
1,5,7,8,5
1,5,7,8,6
1,5,7,8,7
1,5,7,8,9
1,5,7,9,12
1,5,7,9,2
1,5,7,9,3
1,5,7,9,4
1,5,8,8,10
1,5,8,8,4
1,5,8,8,5
1,5,8,8,6
1,5,8,9,11
1,5,8,9,13
1,5,8,9,5
1,5,9,9,14
1,5,9,9,5
1,5,9,9,6
1,6,6,6,18
1,6,6,6,19
1,6,6,6,6
1,6,6,6,7
1,6,6,6,8
1,6,6,7,6
1,6,6,7,7
1,6,6,7,8
1,6,6,8,5
1,6,6,8,6
1,6,6,8,8
1,6,6,8,9
1,6,6,9,10
1,6,6,9,2
1,6,6,9,4
1,6,6,9,9
1,6,7,7,6
1,6,7,7,7
1,6,7,7,8
1,6,7,8,10
1,6,7,8,6
1,6,7,8,7
1,6,7,8,8
1,6,7,8,9
1,6,7,9,11
1,6,7,9,3
1,6,7,9,9
1,6,8,8,11
1,6,8,8,6
1,6,8,9,10
1,6,8,9,11
1,6,8,9,12
1,6,8,9,5
1,6,8,9,6
1,6,8,9,8
1,6,9,9,5
1,6,9,9,7
1,7,7,7,2
1,7,7,7,5
1,7,7,7,6
1,7,7,8,10
1,7,7,8,6
1,7,7,8,7
1,7,7,8,8
1,7,7,8,9
1,7,7,9,10
1,7,7,9,8
1,7,7,9,9
1,7,8,8,10
1,7,8,8,15
1,7,8,8,7
1,7,8,8,8
1,7,8,9,10
1,7,8,9,5
1,7,8,9,7
1,7,8,9,9
1,7,9,9,10
1,7,9,9,6
1,7,9,9,7
1,8,8,8,10
1,8,8,8,8
1,8,8,8,9
1,8,8,9,10
1,8,8,9,6
1,8,8,9,9
1,8,9,9,11
1,8,9,9,8
1,8,9,9,9
1,9,9,9,10
1,9,9,9,9
10,10,10,10,1
10,10,10,10,2
10,10,10,2,1
10,10,10,2,11
10,10,10,2,12
10,10,10,3,13
10,10,10,3,7
10,10,10,4,1
10,10,10,4,6
10,10,10,5,15
10,10,10,6,4
10,10,10,7,18
10,10,10,8,18
10,10,10,8,2
10,10,10,9,1
10,10,10,9,19
10,10,10,9,2
10,10,2,2,1
10,10,2,2,11
10,10,2,2,12
10,10,2,2,13
10,10,2,2,4
10,10,2,2,5
10,10,2,3,2
10,10,2,3,6
10,10,2,3,7
10,10,2,3,9
10,10,2,4,12
10,10,2,4,13
10,10,2,4,4
10,10,2,4,5
10,10,2,4,6
10,10,2,4,8
10,10,2,5,10
10,10,2,5,13
10,10,2,5,17
10,10,2,5,3
10,10,2,5,7
10,10,2,6,1
10,10,2,6,16
10,10,2,6,2
10,10,2,6,3
10,10,2,6,4
10,10,2,6,8
10,10,2,7,11
10,10,2,7,14
10,10,2,7,15
10,10,2,7,5
10,10,2,8,1
10,10,2,8,11
10,10,2,8,13
10,10,2,8,18
10,10,2,8,4
10,10,2,8,6
10,10,2,9,1
10,10,2,9,10
10,10,2,9,11
10,10,2,9,12
10,10,2,9,13
10,10,2,9,19
10,10,2,9,9
10,10,3,4,1
10,10,3,4,12
10,10,3,4,19
10,10,3,4,2
10,10,3,4,6
10,10,3,5,15
10,10,3,5,17
10,10,3,5,2
10,10,3,5,8
10,10,3,5,9
10,10,3,6,16
10,10,3,6,17
10,10,3,6,4
10,10,3,7,10
10,10,3,7,18
10,10,3,8,1
10,10,3,8,11
10,10,3,8,2
10,10,3,8,3
10,10,3,9,1
10,10,3,9,14
10,10,3,9,18
10,10,3,9,19
10,10,3,9,3
10,10,3,9,6
10,10,3,9,7
10,10,3,9,8
10,10,4,4,1
10,10,4,4,12
10,10,4,4,19
10,10,4,5,10
10,10,4,5,14
10,10,4,5,2
10,10,4,5,9
10,10,4,6,16
10,10,4,7,14
10,10,4,7,17
10,10,4,7,9
10,10,4,8,16
10,10,4,8,18
10,10,4,8,19
10,10,4,9,1
10,10,4,9,19
10,10,4,9,5
10,10,5,5,4
10,10,5,6,1
10,10,5,6,11
10,10,5,6,19
10,10,5,6,2
10,10,5,7,12
10,10,5,7,19
10,10,5,8,13
10,10,5,8,14
10,10,5,8,17
10,10,5,8,3
10,10,5,9,1
10,10,5,9,14
10,10,5,9,3
10,10,5,9,4
10,10,6,6,1
10,10,6,7,10
10,10,6,7,13
10,10,6,7,19
10,10,6,7,7
10,10,6,8,14
10,10,6,8,15
10,10,6,8,2
10,10,6,8,3
10,10,6,8,6
10,10,6,9,2
10,10,7,7,1
10,10,7,7,6
10,10,7,8,1
10,10,7,8,15
10,10,7,8,5
10,10,7,9,16
10,10,7,9,2
10,10,7,9,3
10,10,8,8,1
10,10,8,8,14
10,10,8,8,16
10,10,8,8,4
10,10,8,9,1
10,10,8,9,14
10,10,8,9,17
10,10,8,9,19
10,10,8,9,4
10,10,9,9,1
10,2,2,2,11
10,2,2,2,16
10,2,2,2,5
10,2,2,3,1
10,2,2,3,11
10,2,2,3,12
10,2,2,3,13
10,2,2,3,15
10,2,2,3,16
10,2,2,3,6
10,2,2,3,8
10,2,2,4,1
10,2,2,4,10
10,2,2,4,15
10,2,2,4,16
10,2,2,4,18
10,2,2,4,5
10,2,2,4,7
10,2,2,5,1
10,2,2,5,11
10,2,2,5,15
10,2,2,5,16
10,2,2,5,17
10,2,2,5,18
10,2,2,5,19
10,2,2,5,2
10,2,2,5,5
10,2,2,5,8
10,2,2,5,9
10,2,2,6,12
10,2,2,6,13
10,2,2,6,15
10,2,2,6,16
10,2,2,6,2
10,2,2,6,5
10,2,2,6,8
10,2,2,7,12
10,2,2,7,13
10,2,2,7,14
10,2,2,7,15
10,2,2,7,17
10,2,2,7,3
10,2,2,7,6
10,2,2,7,7
10,2,2,8,17
10,2,2,8,2
10,2,2,8,4
10,2,2,8,6
10,2,2,9,1
10,2,2,9,12
10,2,2,9,13
10,2,2,9,16
10,2,2,9,19
10,2,2,9,2
10,2,2,9,3
10,2,3,3,1
10,2,3,3,11
10,2,3,3,13
10,2,3,3,6
10,2,3,3,7
10,2,3,3,8
10,2,3,4,12
10,2,3,4,16
10,2,3,4,17
10,2,3,4,18
10,2,3,4,19
10,2,3,4,5
10,2,3,4,6
10,2,3,4,7
10,2,3,4,8
10,2,3,5,13
10,2,3,5,14
10,2,3,5,18
10,2,3,5,19
10,2,3,5,2
10,2,3,5,3
10,2,3,5,6
10,2,3,6,10
10,2,3,6,11
10,2,3,6,12
10,2,3,6,13
10,2,3,6,16
10,2,3,6,18
10,2,3,6,4
10,2,3,6,5
10,2,3,6,7
10,2,3,6,9
10,2,3,7,13
10,2,3,7,16
10,2,3,7,18
10,2,3,7,19
10,2,3,7,2
10,2,3,7,7
10,2,3,8,12
10,2,3,8,13
10,2,3,8,14
10,2,3,8,16
10,2,3,8,17
10,2,3,8,18
10,2,3,8,2
10,2,3,8,3
10,2,3,8,6
10,2,3,8,8
10,2,3,9,1
10,2,3,9,11
10,2,3,9,12
10,2,3,9,13
10,2,3,9,14
10,2,3,9,15
10,2,3,9,2
10,2,3,9,5
10,2,3,9,8
10,2,4,4,13
10,2,4,4,16
10,2,4,4,19
10,2,4,4,4
10,2,4,4,8
10,2,4,5,10
10,2,4,5,11
10,2,4,5,12
10,2,4,5,13
10,2,4,5,14
10,2,4,5,16
10,2,4,5,18
10,2,4,5,19
10,2,4,5,2
10,2,4,5,5
10,2,4,5,7
10,2,4,5,8
10,2,4,5,9
10,2,4,6,12
10,2,4,6,13
10,2,4,6,14
10,2,4,6,15
10,2,4,6,16
10,2,4,6,18
10,2,4,6,3
10,2,4,6,4
10,2,4,6,6
10,2,4,6,7
10,2,4,6,8
10,2,4,7,1
10,2,4,7,10
10,2,4,7,17
10,2,4,7,3
10,2,4,7,6
10,2,4,7,7
10,2,4,8,10
10,2,4,8,11
10,2,4,8,12
10,2,4,8,16
10,2,4,8,18
10,2,4,8,2
10,2,4,8,3
10,2,4,8,5
10,2,4,9,1
10,2,4,9,12
10,2,4,9,13
10,2,4,9,16
10,2,4,9,17
10,2,4,9,19
10,2,4,9,2
10,2,4,9,3
10,2,4,9,5
10,2,4,9,8
10,2,5,5,12
10,2,5,5,15
10,2,5,5,3
10,2,5,5,5
10,2,5,6,10
10,2,5,6,11
10,2,5,6,12
10,2,5,6,15
10,2,5,6,16
10,2,5,6,3
10,2,5,6,6
10,2,5,6,7
10,2,5,6,9
10,2,5,7,1
10,2,5,7,10
10,2,5,7,17
10,2,5,7,18
10,2,5,7,19
10,2,5,7,3
10,2,5,7,4
10,2,5,7,7
10,2,5,7,9
10,2,5,8,12
10,2,5,8,14
10,2,5,8,15
10,2,5,8,2
10,2,5,8,3
10,2,5,8,5
10,2,5,8,6
10,2,5,8,8
10,2,5,8,9
10,2,5,9,13
10,2,5,9,15
10,2,5,9,16
10,2,5,9,4
10,2,5,9,5
10,2,5,9,6
10,2,5,9,9
10,2,6,6,12
10,2,6,6,5
10,2,6,6,8
10,2,6,7,1
10,2,6,7,18
10,2,6,7,5
10,2,6,7,6
10,2,6,7,7
10,2,6,8,10
10,2,6,8,14
10,2,6,8,18
10,2,6,8,2
10,2,6,8,5
10,2,6,8,6
10,2,6,9,13
10,2,6,9,16
10,2,6,9,5
10,2,6,9,7
10,2,7,7,12
10,2,7,7,19
10,2,7,7,2
10,2,7,7,7
10,2,7,7,8
10,2,7,8,11
10,2,7,8,13
10,2,7,8,19
10,2,7,8,3
10,2,7,8,7
10,2,7,9,1
10,2,7,9,10
10,2,7,9,11
10,2,7,9,4
10,2,7,9,6
10,2,7,9,7
10,2,8,8,12
10,2,8,8,2
10,2,8,8,4
10,2,8,8,5
10,2,8,8,6
10,2,8,8,8
10,2,8,9,1
10,2,8,9,11
10,2,8,9,13
10,2,8,9,16
10,2,8,9,18
10,2,8,9,19
10,2,8,9,5
10,2,8,9,7
10,2,8,9,9
10,2,9,9,12
10,2,9,9,13
10,2,9,9,6
10,3,3,3,19
10,3,3,3,4
10,3,3,4,1
10,3,3,4,14
10,3,3,4,15
10,3,3,4,19
10,3,3,4,5
10,3,3,4,6
10,3,3,4,7
10,3,3,5,1
10,3,3,5,10
10,3,3,5,15
10,3,3,5,17
10,3,3,5,2
10,3,3,5,7
10,3,3,6,1
10,3,3,6,10
10,3,3,6,11
10,3,3,6,13
10,3,3,6,16
10,3,3,6,17
10,3,3,6,4
10,3,3,6,9
10,3,3,7,10
10,3,3,7,16
10,3,3,7,17
10,3,3,7,3
10,3,3,7,5
10,3,3,7,9
10,3,3,8,11
10,3,3,8,3
10,3,3,8,5
10,3,3,9,13
10,3,3,9,2
10,3,3,9,7
10,3,4,4,1
10,3,4,4,13
10,3,4,4,14
10,3,4,4,8
10,3,4,4,9
10,3,4,5,1
10,3,4,5,10
10,3,4,5,11
10,3,4,5,14
10,3,4,5,15
10,3,4,5,16
10,3,4,5,2
10,3,4,5,3
10,3,4,5,5
10,3,4,5,9
10,3,4,6,10
10,3,4,6,12
10,3,4,6,16
10,3,4,6,2
10,3,4,6,3
10,3,4,6,4
10,3,4,6,5
10,3,4,6,9
10,3,4,7,1
10,3,4,7,13
10,3,4,7,17
10,3,4,7,18
10,3,4,7,3
10,3,4,7,7
10,3,4,8,12
10,3,4,8,14
10,3,4,8,17
10,3,4,8,18
10,3,4,8,19
10,3,4,8,3
10,3,4,8,6
10,3,4,8,7
10,3,4,8,9
10,3,4,9,1
10,3,4,9,10
10,3,4,9,19
10,3,4,9,5
10,3,4,9,6
10,3,5,5,12
10,3,5,5,13
10,3,5,5,15
10,3,5,5,4
10,3,5,5,5
10,3,5,5,6
10,3,5,6,1
10,3,5,6,13
10,3,5,6,19
10,3,5,6,4
10,3,5,6,6
10,3,5,7,1
10,3,5,7,12
10,3,5,7,13
10,3,5,7,14
10,3,5,7,17
10,3,5,7,19
10,3,5,7,2
10,3,5,7,3
10,3,5,7,5
10,3,5,7,6
10,3,5,8,10
10,3,5,8,13
10,3,5,8,18
10,3,5,8,3
10,3,5,8,4
10,3,5,8,7
10,3,5,9,1
10,3,5,9,11
10,3,5,9,12
10,3,5,9,14
10,3,5,9,15
10,3,5,9,5
10,3,5,9,6
10,3,6,6,1
10,3,6,6,12
10,3,6,6,13
10,3,6,6,4
10,3,6,6,5
10,3,6,6,7
10,3,6,6,8
10,3,6,7,1
10,3,6,7,14
10,3,6,8,15
10,3,6,8,2
10,3,6,9,10
10,3,6,9,15
10,3,6,9,2
10,3,6,9,3
10,3,6,9,4
10,3,7,7,14
10,3,7,7,17
10,3,7,7,3
10,3,7,7,7
10,3,7,8,14
10,3,7,8,15
10,3,7,8,16
10,3,7,8,19
10,3,7,8,2
10,3,7,8,6
10,3,7,9,1
10,3,7,9,15
10,3,7,9,17
10,3,7,9,18
10,3,7,9,2
10,3,7,9,3
10,3,7,9,5
10,3,7,9,9
10,3,8,8,1
10,3,8,8,18
10,3,8,8,2
10,3,8,8,9
10,3,8,9,1
10,3,8,9,17
10,3,8,9,8
10,3,9,9,1
10,3,9,9,18
10,3,9,9,5
10,4,4,4,1
10,4,4,4,6
10,4,4,5,2
10,4,4,5,3
10,4,4,5,5
10,4,4,5,6
10,4,4,6,16
10,4,4,6,4
10,4,4,6,5
10,4,4,7,17
10,4,4,7,3
10,4,4,8,10
10,4,4,8,16
10,4,4,8,18
10,4,4,8,2
10,4,4,9,1
10,4,4,9,10
10,4,4,9,18
10,4,4,9,19
10,4,5,5,1
10,4,5,5,12
10,4,5,5,15
10,4,5,5,16
10,4,5,5,2
10,4,5,5,5
10,4,5,6,15
10,4,5,6,16
10,4,5,6,17
10,4,5,6,3
10,4,5,6,5
10,4,5,7,1
10,4,5,7,12
10,4,5,7,14
10,4,5,7,15
10,4,5,7,16
10,4,5,7,17
10,4,5,7,2
10,4,5,7,6
10,4,5,8,1
10,4,5,8,11
10,4,5,8,13
10,4,5,8,14
10,4,5,8,15
10,4,5,8,19
10,4,5,8,3
10,4,5,8,4
10,4,5,8,5
10,4,5,9,1
10,4,5,9,11
10,4,5,9,13
10,4,5,9,18
10,4,5,9,19
10,4,5,9,9
10,4,6,6,12
10,4,6,6,16
10,4,6,6,17
10,4,6,7,13
10,4,6,7,18
10,4,6,7,19
10,4,6,7,2
10,4,6,7,5
10,4,6,8,10
10,4,6,8,16
10,4,6,8,18
10,4,6,8,19
10,4,6,8,2
10,4,6,8,4
10,4,6,8,8
10,4,6,9,1
10,4,6,9,11
10,4,6,9,13
10,4,6,9,3
10,4,7,7,14
10,4,7,7,8
10,4,7,8,13
10,4,7,8,15
10,4,7,8,19
10,4,7,8,3
10,4,7,8,4
10,4,7,8,5
10,4,7,9,13
10,4,7,9,15
10,4,7,9,16
10,4,7,9,17
10,4,7,9,19
10,4,7,9,3
10,4,7,9,4
10,4,7,9,5
10,4,8,8,16
10,4,8,8,2
10,4,8,9,10
10,4,8,9,13
10,4,8,9,16
10,4,8,9,17
10,4,8,9,2
10,4,8,9,3
10,4,8,9,5
10,4,8,9,7
10,4,9,9,1
10,4,9,9,19
10,4,9,9,4
10,5,5,5,14
10,5,5,5,5
10,5,5,5,6
10,5,5,6,13
10,5,5,6,15
10,5,5,6,16
10,5,5,6,4
10,5,5,6,5
10,5,5,6,6
10,5,5,7,1
10,5,5,7,13
10,5,5,7,15
10,5,5,7,18
10,5,5,7,3
10,5,5,7,7
10,5,5,8,12
10,5,5,8,15
10,5,5,8,18
10,5,5,8,8
10,5,6,6,14
10,5,6,6,17
10,5,6,6,2
10,5,6,6,3
10,5,6,6,5
10,5,6,7,11
10,5,6,7,12
10,5,6,7,14
10,5,6,7,16
10,5,6,7,18
10,5,6,7,2
10,5,6,7,3
10,5,6,7,8
10,5,6,8,13
10,5,6,8,16
10,5,6,8,17
10,5,6,8,19
10,5,6,8,3
10,5,6,8,7
10,5,6,9,1
10,5,6,9,12
10,5,6,9,18
10,5,6,9,2
10,5,6,9,3
10,5,6,9,8
10,5,7,7,1
10,5,7,7,15
10,5,7,7,2
10,5,7,7,5
10,5,7,8,10
10,5,7,8,13
10,5,7,8,14
10,5,7,8,6
10,5,7,9,1
10,5,7,9,11
10,5,7,9,13
10,5,7,9,17
10,5,7,9,18
10,5,7,9,3
10,5,8,8,1
10,5,8,8,14
10,5,8,8,15
10,5,8,8,5
10,5,8,9,12
10,5,8,9,14
10,5,8,9,15
10,5,8,9,16
10,5,8,9,19
10,5,8,9,2
10,5,8,9,6
10,5,9,9,13
10,5,9,9,15
10,5,9,9,3
10,5,9,9,5
10,6,6,6,16
10,6,6,6,2
10,6,6,6,4
10,6,6,6,5
10,6,6,6,8
10,6,6,7,15
10,6,6,7,18
10,6,6,8,10
10,6,6,8,14
10,6,6,8,19
10,6,6,8,3
10,6,6,8,6
10,6,6,9,13
10,6,6,9,2
10,6,6,9,9
10,6,7,7,17
10,6,7,8,1
10,6,7,8,11
10,6,7,8,17
10,6,7,8,18
10,6,7,8,19
10,6,7,8,3
10,6,7,8,5
10,6,7,9,12
10,6,7,9,2
10,6,7,9,3
10,6,7,9,6
10,6,8,8,16
10,6,8,8,2
10,6,8,8,4
10,6,8,9,1
10,6,8,9,13
10,6,8,9,17
10,6,8,9,3
10,6,9,9,14
10,7,7,7,17
10,7,7,8,1
10,7,7,8,16
10,7,7,8,18
10,7,7,8,19
10,7,7,8,2
10,7,7,8,4
10,7,7,9,13
10,7,7,9,15
10,7,7,9,19
10,7,7,9,5
10,7,7,9,7
10,7,8,8,13
10,7,8,8,17
10,7,8,8,19
10,7,8,8,3
10,7,8,8,4
10,7,8,9,1
10,7,8,9,14
10,7,8,9,16
10,7,8,9,2
10,7,8,9,4
10,7,9,9,1
10,7,9,9,11
10,7,9,9,18
10,7,9,9,3
10,8,8,8,16
10,8,8,9,1
10,8,8,9,15
10,8,8,9,17
10,8,8,9,19
10,8,9,9,18
10,8,9,9,19
10,8,9,9,2
10,8,9,9,3
10,9,9,9,17
2,2,2,2,1
2,2,2,2,3
2,2,2,2,4
2,2,2,3,16
2,2,2,3,4
2,2,2,3,5
2,2,2,3,6
2,2,2,3,9
2,2,2,4,5
2,2,2,4,6
2,2,2,4,8
2,2,2,4,9
2,2,2,5,11
2,2,2,5,14
2,2,2,5,4
2,2,2,5,6
2,2,2,5,8
2,2,2,6,12
2,2,2,6,16
2,2,2,6,6
2,2,2,7,10
2,2,2,7,5
2,2,2,7,9
2,2,2,8,14
2,2,2,8,16
2,2,2,8,2
2,2,2,8,5
2,2,2,8,6
2,2,2,9,12
2,2,2,9,15
2,2,2,9,18
2,2,2,9,19
2,2,2,9,7
2,2,3,3,1
2,2,3,3,4
2,2,3,3,5
2,2,3,4,1
2,2,3,4,10
2,2,3,4,11
2,2,3,4,13
2,2,3,4,16
2,2,3,4,2
2,2,3,4,4
2,2,3,4,5
2,2,3,4,8
2,2,3,4,9
2,2,3,5,10
2,2,3,5,12
2,2,3,5,15
2,2,3,5,17
2,2,3,5,18
2,2,3,5,4
2,2,3,5,8
2,2,3,5,9
2,2,3,6,1
2,2,3,6,10
2,2,3,6,12
2,2,3,6,13
2,2,3,6,16
2,2,3,6,17
2,2,3,6,18
2,2,3,6,3
2,2,3,6,4
2,2,3,6,6
2,2,3,6,7
2,2,3,6,8
2,2,3,6,9
2,2,3,7,1
2,2,3,7,10
2,2,3,7,13
2,2,3,7,14
2,2,3,7,18
2,2,3,7,19
2,2,3,7,4
2,2,3,7,5
2,2,3,7,6
2,2,3,7,7
2,2,3,7,8
2,2,3,7,9
2,2,3,8,1
2,2,3,8,11
2,2,3,8,14
2,2,3,8,15
2,2,3,8,3
2,2,3,8,4
2,2,3,8,6
2,2,3,8,7
2,2,3,9,1
2,2,3,9,10
2,2,3,9,11
2,2,3,9,12
2,2,3,9,13
2,2,3,9,15
2,2,3,9,16
2,2,3,9,18
2,2,3,9,2
2,2,3,9,6
2,2,3,9,7
2,2,3,9,8
2,2,4,4,1
2,2,4,4,10
2,2,4,4,12
2,2,4,4,14
2,2,4,4,2
2,2,4,4,3
2,2,4,4,4
2,2,4,4,5
2,2,4,4,8
2,2,4,5,1
2,2,4,5,10
2,2,4,5,11
2,2,4,5,13
2,2,4,5,16
2,2,4,5,17
2,2,4,5,2
2,2,4,5,3
2,2,4,5,5
2,2,4,5,7
2,2,4,6,1
2,2,4,6,12
2,2,4,6,14
2,2,4,6,16
2,2,4,6,18
2,2,4,6,2
2,2,4,6,3
2,2,4,6,4
2,2,4,6,5
2,2,4,6,7
2,2,4,6,8
2,2,4,6,9
2,2,4,7,1
2,2,4,7,10
2,2,4,7,11
2,2,4,7,13
2,2,4,7,14
2,2,4,7,15
2,2,4,7,18
2,2,4,7,3
2,2,4,7,9
2,2,4,8,10
2,2,4,8,12
2,2,4,8,16
2,2,4,8,18
2,2,4,8,2
2,2,4,8,4
2,2,4,8,5
2,2,4,8,6
2,2,4,8,8
2,2,4,9,14
2,2,4,9,18
2,2,4,9,2
2,2,4,9,6
2,2,4,9,7
2,2,4,9,9
2,2,5,5,4
2,2,5,5,6
2,2,5,6,11
2,2,5,6,13
2,2,5,6,14
2,2,5,6,15
2,2,5,6,18
2,2,5,6,19
2,2,5,6,6
2,2,5,7,10
2,2,5,7,11
2,2,5,7,15
2,2,5,7,16
2,2,5,7,19
2,2,5,7,2
2,2,5,7,6
2,2,5,7,8
2,2,5,8,10
2,2,5,8,11
2,2,5,8,17
2,2,5,8,19
2,2,5,8,7
2,2,5,8,9
2,2,5,9,10
2,2,5,9,12
2,2,5,9,14
2,2,5,9,18
2,2,5,9,19
2,2,6,6,1
2,2,6,6,12
2,2,6,6,16
2,2,6,6,4
2,2,6,7,1
2,2,6,7,17
2,2,6,7,3
2,2,6,7,9
2,2,6,8,14
2,2,6,8,18
2,2,6,8,2
2,2,6,8,3
2,2,6,8,4
2,2,6,8,7
2,2,6,8,8
2,2,6,8,9
2,2,6,9,12
2,2,6,9,19
2,2,6,9,7
2,2,7,7,1
2,2,7,7,14
2,2,7,7,4
2,2,7,8,1
2,2,7,8,18
2,2,7,8,19
2,2,7,9,1
2,2,7,9,16
2,2,7,9,2
2,2,7,9,8
2,2,8,8,1
2,2,8,8,16
2,2,8,8,17
2,2,8,8,6
2,2,8,9,16
2,2,8,9,17
2,2,8,9,2
2,2,8,9,5
2,2,8,9,7
2,2,9,9,14
2,2,9,9,18
2,2,9,9,2
2,2,9,9,4
2,3,3,3,1
2,3,3,3,11
2,3,3,3,12
2,3,3,3,14
2,3,3,3,2
2,3,3,3,5
2,3,3,3,6
2,3,3,4,1
2,3,3,4,10
2,3,3,4,11
2,3,3,4,12
2,3,3,4,18
2,3,3,4,2
2,3,3,4,4
2,3,3,4,5
2,3,3,4,6
2,3,3,4,8
2,3,3,5,10
2,3,3,5,11
2,3,3,5,14
2,3,3,5,15
2,3,3,5,2
2,3,3,5,3
2,3,3,5,5
2,3,3,5,7
2,3,3,5,8
2,3,3,6,10
2,3,3,6,12
2,3,3,6,13
2,3,3,6,14
2,3,3,6,2
2,3,3,6,3
2,3,3,6,4
2,3,3,6,5
2,3,3,6,6
2,3,3,6,9
2,3,3,7,10
2,3,3,7,13
2,3,3,7,14
2,3,3,7,15
2,3,3,7,16
2,3,3,7,18
2,3,3,7,3
2,3,3,7,4
2,3,3,7,5
2,3,3,8,10
2,3,3,8,11
2,3,3,8,15
2,3,3,8,16
2,3,3,8,4
2,3,3,8,7
2,3,3,8,9
2,3,3,9,10
2,3,3,9,15
2,3,3,9,18
2,3,3,9,2
2,3,3,9,6
2,3,3,9,7
2,3,3,9,8
2,3,3,9,9
2,3,4,4,1
2,3,4,4,11
2,3,4,4,14
2,3,4,4,4
2,3,4,4,5
2,3,4,4,6
2,3,4,4,7
2,3,4,4,8
2,3,4,5,1
2,3,4,5,10
2,3,4,5,11
2,3,4,5,12
2,3,4,5,13
2,3,4,5,14
2,3,4,5,15
2,3,4,5,17
2,3,4,5,18
2,3,4,5,3
2,3,4,5,4
2,3,4,5,5
2,3,4,5,7
2,3,4,5,9
2,3,4,6,1
2,3,4,6,11
2,3,4,6,12
2,3,4,6,13
2,3,4,6,14
2,3,4,6,15
2,3,4,6,16
2,3,4,6,2
2,3,4,6,3
2,3,4,6,4
2,3,4,6,5
2,3,4,7,1
2,3,4,7,12
2,3,4,7,14
2,3,4,7,15
2,3,4,7,2
2,3,4,7,3
2,3,4,7,5
2,3,4,7,8
2,3,4,7,9
2,3,4,8,1
2,3,4,8,11
2,3,4,8,12
2,3,4,8,14
2,3,4,8,15
2,3,4,8,16
2,3,4,8,17
2,3,4,8,18
2,3,4,8,2
2,3,4,8,4
2,3,4,8,5
2,3,4,8,6
2,3,4,8,8
2,3,4,8,9
2,3,4,9,1
2,3,4,9,12
2,3,4,9,13
2,3,4,9,15
2,3,4,9,17
2,3,4,9,18
2,3,4,9,19
2,3,4,9,2
2,3,4,9,4
2,3,4,9,6
2,3,4,9,7
2,3,4,9,8
2,3,5,5,2
2,3,5,5,7
2,3,5,5,9
2,3,5,6,10
2,3,5,6,11
2,3,5,6,12
2,3,5,6,13
2,3,5,6,15
2,3,5,6,19
2,3,5,6,3
2,3,5,6,4
2,3,5,6,5
2,3,5,6,6
2,3,5,6,7
2,3,5,6,9
2,3,5,7,1
2,3,5,7,12
2,3,5,7,13
2,3,5,7,15
2,3,5,7,16
2,3,5,7,17
2,3,5,7,19
2,3,5,7,2
2,3,5,7,7
2,3,5,7,9
2,3,5,8,12
2,3,5,8,13
2,3,5,8,14
2,3,5,8,16
2,3,5,8,17
2,3,5,8,18
2,3,5,8,2
2,3,5,8,5
2,3,5,8,8
2,3,5,9,10
2,3,5,9,13
2,3,5,9,15
2,3,5,9,17
2,3,5,9,18
2,3,5,9,19
2,3,5,9,2
2,3,5,9,3
2,3,5,9,4
2,3,5,9,6
2,3,5,9,9
2,3,6,6,1
2,3,6,6,11
2,3,6,6,13
2,3,6,6,14
2,3,6,6,16
2,3,6,6,4
2,3,6,6,5
2,3,6,6,6
2,3,6,6,8
2,3,6,7,11
2,3,6,7,12
2,3,6,7,14
2,3,6,7,16
2,3,6,7,17
2,3,6,7,18
2,3,6,7,19
2,3,6,7,3
2,3,6,7,4
2,3,6,7,5
2,3,6,7,8
2,3,6,8,1
2,3,6,8,12
2,3,6,8,18
2,3,6,8,19
2,3,6,8,2
2,3,6,8,3
2,3,6,8,7
2,3,6,8,8
2,3,6,9,1
2,3,6,9,10
2,3,6,9,12
2,3,6,9,19
2,3,6,9,2
2,3,6,9,4
2,3,6,9,5
2,3,6,9,7
2,3,6,9,8
2,3,6,9,9
2,3,7,7,1
2,3,7,7,14
2,3,7,7,15
2,3,7,7,18
2,3,7,7,19
2,3,7,7,2
2,3,7,7,5
2,3,7,7,9
2,3,7,8,10
2,3,7,8,12
2,3,7,8,14
2,3,7,8,15
2,3,7,8,16
2,3,7,8,19
2,3,7,8,4
2,3,7,8,5
2,3,7,8,6
2,3,7,9,10
2,3,7,9,11
2,3,7,9,14
2,3,7,9,15
2,3,7,9,16
2,3,7,9,17
2,3,7,9,2
2,3,8,8,1
2,3,8,8,11
2,3,8,8,12
2,3,8,8,16
2,3,8,8,2
2,3,8,8,9
2,3,8,9,11
2,3,8,9,12
2,3,8,9,13
2,3,8,9,17
2,3,8,9,18
2,3,8,9,3
2,3,9,9,11
2,3,9,9,14
2,3,9,9,18
2,3,9,9,4
2,3,9,9,5
2,3,9,9,6
2,4,4,4,3
2,4,4,4,6
2,4,4,4,8
2,4,4,4,9
2,4,4,5,1
2,4,4,5,10
2,4,4,5,11
2,4,4,5,15
2,4,4,5,18
2,4,4,5,3
2,4,4,5,4
2,4,4,5,7
2,4,4,5,8
2,4,4,6,1
2,4,4,6,12
2,4,4,6,13
2,4,4,6,14
2,4,4,6,16
2,4,4,6,2
2,4,4,6,3
2,4,4,6,4
2,4,4,6,8
2,4,4,7,10
2,4,4,7,17
2,4,4,7,2
2,4,4,7,4
2,4,4,7,5
2,4,4,8,1
2,4,4,8,12
2,4,4,8,16
2,4,4,8,17
2,4,4,8,18
2,4,4,8,3
2,4,4,8,4
2,4,4,8,5
2,4,4,8,8
2,4,4,9,11
2,4,4,9,15
2,4,4,9,19
2,4,4,9,2
2,4,4,9,3
2,4,5,5,1
2,4,5,5,11
2,4,5,5,14
2,4,5,5,16
2,4,5,5,3
2,4,5,5,4
2,4,5,5,6
2,4,5,5,7
2,4,5,6,10
2,4,5,6,12
2,4,5,6,18
2,4,5,6,19
2,4,5,6,2
2,4,5,6,3
2,4,5,6,6
2,4,5,6,7
2,4,5,6,8
2,4,5,7,12
2,4,5,7,14
2,4,5,7,16
2,4,5,7,19
2,4,5,7,2
2,4,5,7,3
2,4,5,7,5
2,4,5,7,6
2,4,5,8,1
2,4,5,8,10
2,4,5,8,11
2,4,5,8,15
2,4,5,8,17
2,4,5,8,19
2,4,5,8,3
2,4,5,8,4
2,4,5,8,7
2,4,5,8,9
2,4,5,9,1
2,4,5,9,11
2,4,5,9,12
2,4,5,9,14
2,4,5,9,16
2,4,5,9,18
2,4,5,9,2
2,4,5,9,4
2,4,5,9,5
2,4,5,9,6
2,4,5,9,7
2,4,5,9,8
2,4,6,6,10
2,4,6,6,11
2,4,6,6,12
2,4,6,6,13
2,4,6,6,3
2,4,6,6,4
2,4,6,6,7
2,4,6,6,8
2,4,6,7,1
2,4,6,7,12
2,4,6,7,13
2,4,6,7,17
2,4,6,7,3
2,4,6,7,5
2,4,6,7,6
2,4,6,7,9
2,4,6,8,10
2,4,6,8,11
2,4,6,8,12
2,4,6,8,14
2,4,6,8,16
2,4,6,8,18
2,4,6,8,2
2,4,6,8,3
2,4,6,8,4
2,4,6,8,6
2,4,6,8,8
2,4,6,9,1
2,4,6,9,14
2,4,6,9,18
2,4,6,9,19
2,4,6,9,3
2,4,6,9,5
2,4,6,9,6
2,4,6,9,7
2,4,6,9,8
2,4,7,7,1
2,4,7,7,10
2,4,7,7,14
2,4,7,7,18
2,4,7,7,2
2,4,7,7,3
2,4,7,7,5
2,4,7,7,8
2,4,7,8,13
2,4,7,8,14
2,4,7,8,15
2,4,7,8,17
2,4,7,8,18
2,4,7,8,19
2,4,7,8,3
2,4,7,8,4
2,4,7,8,5
2,4,7,8,6
2,4,7,8,9
2,4,7,9,1
2,4,7,9,15
2,4,7,9,16
2,4,7,9,17
2,4,7,9,19
2,4,7,9,2
2,4,7,9,6
2,4,8,8,1
2,4,8,8,10
2,4,8,8,12
2,4,8,8,16
2,4,8,8,2
2,4,8,8,6
2,4,8,9,12
2,4,8,9,14
2,4,8,9,17
2,4,8,9,3
2,4,8,9,6
2,4,8,9,7
2,4,9,9,11
2,4,9,9,16
2,4,9,9,18
2,4,9,9,4
2,4,9,9,7
2,5,5,5,1
2,5,5,5,4
2,5,5,5,7
2,5,5,6,10
2,5,5,6,14
2,5,5,6,18
2,5,5,6,2
2,5,5,6,3
2,5,5,6,4
2,5,5,6,8
2,5,5,7,1
2,5,5,7,12
2,5,5,7,14
2,5,5,7,19
2,5,5,7,5
2,5,5,8,1
2,5,5,8,11
2,5,5,8,4
2,5,5,8,6
2,5,5,8,7
2,5,5,9,10
2,5,5,9,11
2,5,5,9,17
2,5,5,9,18
2,5,5,9,4
2,5,5,9,5
2,5,5,9,7
2,5,6,6,1
2,5,6,6,10
2,5,6,6,11
2,5,6,6,12
2,5,6,6,14
2,5,6,6,15
2,5,6,6,19
2,5,6,6,4
2,5,6,6,9
2,5,6,7,1
2,5,6,7,11
2,5,6,7,13
2,5,6,7,14
2,5,6,7,15
2,5,6,7,16
2,5,6,7,2
2,5,6,7,3
2,5,6,7,4
2,5,6,7,5
2,5,6,7,6
2,5,6,7,9
2,5,6,8,1
2,5,6,8,14
2,5,6,8,15
2,5,6,8,17
2,5,6,8,4
2,5,6,8,7
2,5,6,8,8
2,5,6,8,9
2,5,6,9,10
2,5,6,9,12
2,5,6,9,15
2,5,6,9,16
2,5,6,9,18
2,5,6,9,5
2,5,7,7,10
2,5,7,7,2
2,5,7,7,3
2,5,7,7,7
2,5,7,8,14
2,5,7,8,16
2,5,7,8,17
2,5,7,8,18
2,5,7,8,19
2,5,7,8,3
2,5,7,8,4
2,5,7,8,8
2,5,7,9,1
2,5,7,9,13
2,5,7,9,14
2,5,7,9,17
2,5,7,9,5
2,5,7,9,6
2,5,7,9,9
2,5,8,8,13
2,5,8,8,14
2,5,8,8,17
2,5,8,8,3
2,5,8,8,8
2,5,8,8,9
2,5,8,9,10
2,5,8,9,2
2,5,8,9,4
2,5,8,9,6
2,5,9,9,3
2,5,9,9,7
2,5,9,9,8
2,6,6,6,1
2,6,6,6,13
2,6,6,6,3
2,6,6,7,1
2,6,6,7,14
2,6,6,7,4
2,6,6,7,5
2,6,6,7,9
2,6,6,8,15
2,6,6,8,18
2,6,6,8,2
2,6,6,8,4
2,6,6,8,5
2,6,6,8,6
2,6,6,9,1
2,6,6,9,11
2,6,6,9,15
2,6,6,9,5
2,6,6,9,6
2,6,6,9,7
2,6,6,9,9
2,6,7,7,1
2,6,7,7,12
2,6,7,7,2
2,6,7,7,3
2,6,7,7,4
2,6,7,8,1
2,6,7,8,11
2,6,7,8,18
2,6,7,8,19
2,6,7,8,3
2,6,7,8,7
2,6,7,8,9
2,6,7,9,12
2,6,7,9,6
2,6,8,8,1
2,6,8,8,14
2,6,8,8,18
2,6,8,8,3
2,6,8,8,4
2,6,8,8,6
2,6,8,8,8
2,6,8,9,13
2,6,8,9,19
2,6,8,9,5
2,6,8,9,7
2,6,9,9,10
2,6,9,9,12
2,6,9,9,3
2,6,9,9,4
2,7,7,7,14
2,7,7,7,5
2,7,7,7,8
2,7,7,8,16
2,7,7,8,4
2,7,7,8,6
2,7,7,8,7
2,7,7,9,16
2,7,7,9,18
2,7,7,9,19
2,7,7,9,3
2,7,7,9,7
2,7,8,8,15
2,7,8,8,17
2,7,8,8,5
2,7,8,8,9
2,7,8,9,10
2,7,8,9,12
2,7,8,9,13
2,7,8,9,16
2,7,8,9,2
2,7,8,9,4
2,7,8,9,6
2,7,8,9,8
2,7,9,9,1
2,7,9,9,13
2,7,9,9,14
2,7,9,9,2
2,7,9,9,5
2,7,9,9,8
2,7,9,9,9
2,8,8,8,11
2,8,8,8,12
2,8,8,8,18
2,8,8,8,6
2,8,8,9,18
2,8,8,9,7
2,8,9,9,10
2,8,9,9,16
2,8,9,9,4
2,8,9,9,6
2,8,9,9,8
2,9,9,9,1
2,9,9,9,11
2,9,9,9,12
2,9,9,9,18
3,3,3,3,7
3,3,3,4,1
3,3,3,4,18
3,3,3,4,5
3,3,3,5,14
3,3,3,5,15
3,3,3,5,2
3,3,3,5,4
3,3,3,5,7
3,3,3,5,8
3,3,3,6,1
3,3,3,6,15
3,3,3,6,3
3,3,3,7,16
3,3,3,7,18
3,3,3,7,3
3,3,3,8,11
3,3,3,8,12
3,3,3,8,17
3,3,3,8,5
3,3,3,9,11
3,3,3,9,12
3,3,3,9,13
3,3,3,9,3
3,3,4,4,1
3,3,4,4,13
3,3,4,4,14
3,3,4,4,15
3,3,4,4,16
3,3,4,4,3
3,3,4,4,8
3,3,4,5,1
3,3,4,5,10
3,3,4,5,14
3,3,4,5,17
3,3,4,5,2
3,3,4,5,6
3,3,4,5,8
3,3,4,5,9
3,3,4,6,10
3,3,4,6,12
3,3,4,6,18
3,3,4,6,2
3,3,4,6,3
3,3,4,6,4
3,3,4,6,8
3,3,4,6,9
3,3,4,7,10
3,3,4,7,12
3,3,4,7,14
3,3,4,7,15
3,3,4,7,16
3,3,4,7,17
3,3,4,7,6
3,3,4,7,9
3,3,4,8,1
3,3,4,8,11
3,3,4,8,12
3,3,4,8,16
3,3,4,8,17
3,3,4,8,4
3,3,4,8,7
3,3,4,9,1
3,3,4,9,18
3,3,4,9,19
3,3,4,9,3
3,3,4,9,4
3,3,4,9,5
3,3,4,9,6
3,3,4,9,7
3,3,4,9,9
3,3,5,5,16
3,3,5,5,9
3,3,5,6,1
3,3,5,6,11
3,3,5,6,14
3,3,5,6,16
3,3,5,6,17
3,3,5,6,2
3,3,5,6,3
3,3,5,7,12
3,3,5,7,18
3,3,5,7,5
3,3,5,7,8
3,3,5,8,13
3,3,5,8,19
3,3,5,8,3
3,3,5,8,8
3,3,5,9,12
3,3,5,9,14
3,3,5,9,2
3,3,5,9,3
3,3,5,9,5
3,3,5,9,6
3,3,6,6,1
3,3,6,6,10
3,3,6,6,13
3,3,6,6,14
3,3,6,6,15
3,3,6,6,18
3,3,6,6,6
3,3,6,6,9
3,3,6,7,12
3,3,6,7,13
3,3,6,7,14
3,3,6,7,15
3,3,6,7,19
3,3,6,8,11
3,3,6,8,12
3,3,6,8,14
3,3,6,8,17
3,3,6,8,2
3,3,6,8,4
3,3,6,8,7
3,3,6,9,16
3,3,6,9,18
3,3,6,9,6
3,3,6,9,8
3,3,7,7,1
3,3,7,8,15
3,3,7,8,4
3,3,7,9,11
3,3,7,9,16
3,3,7,9,19
3,3,7,9,3
3,3,7,9,7
3,3,7,9,8
3,3,8,8,1
3,3,8,8,15
3,3,8,8,16
3,3,8,9,1
3,3,8,9,11
3,3,8,9,14
3,3,8,9,17
3,3,8,9,18
3,3,8,9,3
3,3,9,9,1
3,3,9,9,18
3,3,9,9,19
3,3,9,9,7
3,4,4,4,1
3,4,4,4,15
3,4,4,4,7
3,4,4,4,8
3,4,4,4,9
3,4,4,5,1
3,4,4,5,14
3,4,4,5,15
3,4,4,5,16
3,4,4,5,17
3,4,4,5,2
3,4,4,5,4
3,4,4,5,8
3,4,4,6,10
3,4,4,6,11
3,4,4,6,12
3,4,4,6,15
3,4,4,6,18
3,4,4,6,2
3,4,4,6,3
3,4,4,6,7
3,4,4,6,9
3,4,4,7,11
3,4,4,7,18
3,4,4,7,3
3,4,4,8,1
3,4,4,8,11
3,4,4,8,16
3,4,4,8,17
3,4,4,8,19
3,4,4,8,2
3,4,4,8,3
3,4,4,8,5
3,4,4,8,9
3,4,4,9,1
3,4,4,9,12
3,4,4,9,18
3,4,4,9,19
3,4,4,9,3
3,4,4,9,4
3,4,4,9,6
3,4,5,5,1
3,4,5,5,11
3,4,5,5,16
3,4,5,5,17
3,4,5,5,7
3,4,5,6,1
3,4,5,6,11
3,4,5,6,13
3,4,5,6,17
3,4,5,6,19
3,4,5,6,2
3,4,5,6,8
3,4,5,6,9
3,4,5,7,10
3,4,5,7,12
3,4,5,7,13
3,4,5,7,14
3,4,5,7,18
3,4,5,7,2
3,4,5,7,3
3,4,5,7,4
3,4,5,7,6
3,4,5,7,9
3,4,5,8,1
3,4,5,8,10
3,4,5,8,13
3,4,5,8,14
3,4,5,8,16
3,4,5,8,19
3,4,5,8,2
3,4,5,8,4
3,4,5,8,5
3,4,5,9,1
3,4,5,9,12
3,4,5,9,14
3,4,5,9,15
3,4,5,9,2
3,4,5,9,6
3,4,5,9,8
3,4,5,9,9
3,4,6,6,1
3,4,6,6,10
3,4,6,6,11
3,4,6,6,12
3,4,6,6,13
3,4,6,6,15
3,4,6,6,2
3,4,6,6,5
3,4,6,6,8
3,4,6,6,9
3,4,6,7,1
3,4,6,7,10
3,4,6,7,12
3,4,6,7,13
3,4,6,7,14
3,4,6,7,16
3,4,6,7,17
3,4,6,7,19
3,4,6,7,4
3,4,6,7,6
3,4,6,7,7
3,4,6,8,10
3,4,6,8,11
3,4,6,8,12
3,4,6,8,13
3,4,6,8,16
3,4,6,8,2
3,4,6,8,3
3,4,6,8,4
3,4,6,9,12
3,4,6,9,14
3,4,6,9,18
3,4,6,9,4
3,4,6,9,6
3,4,6,9,9
3,4,7,7,1
3,4,7,7,10
3,4,7,7,12
3,4,7,7,3
3,4,7,7,6
3,4,7,7,7
3,4,7,8,1
3,4,7,8,11
3,4,7,8,12
3,4,7,8,15
3,4,7,8,16
3,4,7,8,2
3,4,7,9,1
3,4,7,9,14
3,4,7,9,15
3,4,7,9,16
3,4,7,9,17
3,4,7,9,18
3,4,8,8,1
3,4,8,8,12
3,4,8,8,16
3,4,8,8,2
3,4,8,8,4
3,4,8,8,8
3,4,8,9,1
3,4,8,9,12
3,4,8,9,13
3,4,8,9,14
3,4,8,9,16
3,4,8,9,17
3,4,8,9,5
3,4,9,9,1
3,4,9,9,13
3,4,9,9,14
3,4,9,9,18
3,4,9,9,19
3,4,9,9,2
3,4,9,9,3
3,4,9,9,5
3,4,9,9,7
3,5,5,5,10
3,5,5,5,9
3,5,5,6,1
3,5,5,6,10
3,5,5,6,12
3,5,5,6,19
3,5,5,6,2
3,5,5,6,9
3,5,5,7,12
3,5,5,7,13
3,5,5,7,4
3,5,5,7,6
3,5,5,8,1
3,5,5,8,10
3,5,5,8,11
3,5,5,8,13
3,5,5,8,14
3,5,5,8,5
3,5,5,9,10
3,5,5,9,13
3,5,5,9,14
3,5,5,9,3
3,5,5,9,6
3,5,6,6,1
3,5,6,6,11
3,5,6,6,14
3,5,6,6,6
3,5,6,7,1
3,5,6,7,11
3,5,6,7,13
3,5,6,7,3
3,5,6,7,9
3,5,6,8,11
3,5,6,8,12
3,5,6,8,13
3,5,6,8,14
3,5,6,8,16
3,5,6,8,3
3,5,6,8,6
3,5,6,9,1
3,5,6,9,11
3,5,6,9,13
3,5,6,9,4
3,5,6,9,7
3,5,7,7,11
3,5,7,7,12
3,5,7,8,1
3,5,7,8,11
3,5,7,8,12
3,5,7,8,13
3,5,7,8,14
3,5,7,8,17
3,5,7,8,7
3,5,7,9,10
3,5,7,9,12
3,5,7,9,14
3,5,7,9,16
3,5,7,9,18
3,5,7,9,2
3,5,7,9,4
3,5,7,9,6
3,5,7,9,8
3,5,8,8,1
3,5,8,8,13
3,5,8,8,15
3,5,8,8,18
3,5,8,8,2
3,5,8,8,3
3,5,8,8,9
3,5,8,9,1
3,5,8,9,14
3,5,8,9,2
3,5,8,9,7
3,5,8,9,9
3,5,9,9,10
3,5,9,9,14
3,5,9,9,15
3,5,9,9,2
3,5,9,9,3
3,5,9,9,4
3,5,9,9,8
3,6,6,6,13
3,6,6,6,14
3,6,6,6,18
3,6,6,6,3
3,6,6,7,1
3,6,6,7,14
3,6,6,7,16
3,6,6,8,14
3,6,6,8,4
3,6,6,9,1
3,6,6,9,11
3,6,6,9,12
3,6,6,9,14
3,6,6,9,15
3,6,6,9,18
3,6,6,9,2
3,6,6,9,3
3,6,6,9,9
3,6,7,7,15
3,6,7,7,17
3,6,7,7,18
3,6,7,7,2
3,6,7,8,1
3,6,7,8,15
3,6,7,8,16
3,6,7,8,18
3,6,7,8,2
3,6,7,9,1
3,6,7,9,13
3,6,7,9,15
3,6,7,9,18
3,6,7,9,19
3,6,7,9,3
3,6,7,9,5
3,6,8,8,12
3,6,8,8,16
3,6,8,8,18
3,6,8,8,19
3,6,8,8,2
3,6,8,8,3
3,6,8,8,8
3,6,8,9,1
3,6,8,9,14
3,6,8,9,17
3,6,8,9,2
3,6,8,9,8
3,6,9,9,1
3,6,9,9,14
3,6,9,9,18
3,6,9,9,3
3,6,9,9,9
3,7,7,7,1
3,7,7,8,1
3,7,7,8,11
3,7,7,8,15
3,7,7,8,19
3,7,7,8,3
3,7,7,8,7
3,7,7,9,11
3,7,7,9,12
3,7,7,9,16
3,7,7,9,17
3,7,7,9,18
3,7,7,9,2
3,7,8,8,1
3,7,8,8,14
3,7,8,8,16
3,7,8,9,1
3,7,8,9,11
3,7,8,9,18
3,7,8,9,7
3,7,9,9,10
3,7,9,9,2
3,7,9,9,4
3,8,8,8,1
3,8,8,8,11
3,8,8,8,16
3,8,8,9,1
3,8,8,9,13
3,8,8,9,17
3,8,8,9,18
3,8,8,9,3
3,8,9,9,1
3,8,9,9,16
3,8,9,9,17
3,8,9,9,18
3,8,9,9,2
3,8,9,9,5
3,9,9,9,1
3,9,9,9,17
3,9,9,9,3
3,9,9,9,7
4,4,4,4,8
4,4,4,5,17
4,4,4,6,10
4,4,4,6,11
4,4,4,6,2
4,4,4,7,11
4,4,4,7,14
4,4,4,7,19
4,4,4,7,5
4,4,4,8,13
4,4,4,9,13
4,4,4,9,18
4,4,5,5,1
4,4,5,5,10
4,4,5,5,18
4,4,5,5,9
4,4,5,6,1
4,4,5,6,11
4,4,5,6,12
4,4,5,6,19
4,4,5,6,8
4,4,5,6,9
4,4,5,7,16
4,4,5,7,2
4,4,5,7,4
4,4,5,8,10
4,4,5,8,11
4,4,5,8,13
4,4,5,8,3
4,4,5,8,5
4,4,5,9,14
4,4,5,9,4
4,4,6,6,1
4,4,6,6,2
4,4,6,7,1
4,4,6,7,13
4,4,6,8,10
4,4,6,8,13
4,4,6,8,14
4,4,6,8,15
4,4,6,8,2
4,4,6,8,6
4,4,6,9,15
4,4,6,9,3
4,4,6,9,4
4,4,6,9,7
4,4,7,7,1
4,4,7,7,14
4,4,7,7,15
4,4,7,7,6
4,4,7,7,9
4,4,7,8,1
4,4,7,8,14
4,4,7,8,15
4,4,7,8,16
4,4,7,8,9
4,4,7,9,10
4,4,7,9,12
4,4,7,9,15
4,4,7,9,2
4,4,7,9,3
4,4,7,9,8
4,4,8,8,8
4,4,8,9,11
4,4,8,9,9
4,4,9,9,1
4,4,9,9,16
4,4,9,9,17
4,4,9,9,18
4,5,5,5,1
4,5,5,5,11
4,5,5,5,2
4,5,5,5,9
4,5,5,6,1
4,5,5,6,12
4,5,5,6,2
4,5,5,7,12
4,5,5,7,2
4,5,5,8,13
4,5,5,8,14
4,5,5,9,1
4,5,5,9,13
4,5,5,9,15
4,5,5,9,6
4,5,5,9,8
4,5,6,6,1
4,5,6,6,10
4,5,6,6,11
4,5,6,7,10
4,5,6,7,11
4,5,6,7,12
4,5,6,7,14
4,5,6,7,2
4,5,6,7,3
4,5,6,7,8
4,5,6,8,1
4,5,6,8,11
4,5,6,8,12
4,5,6,8,13
4,5,6,8,15
4,5,6,8,18
4,5,6,8,5
4,5,6,8,7
4,5,6,9,17
4,5,6,9,2
4,5,6,9,7
4,5,6,9,8
4,5,7,7,10
4,5,7,7,12
4,5,7,7,14
4,5,7,7,2
4,5,7,8,10
4,5,7,8,11
4,5,7,8,12
4,5,7,8,13
4,5,7,8,14
4,5,7,8,15
4,5,7,8,16
4,5,7,8,17
4,5,7,8,2
4,5,7,9,11
4,5,7,9,14
4,5,7,9,15
4,5,7,9,17
4,5,7,9,4
4,5,7,9,7
4,5,8,8,1
4,5,8,8,10
4,5,8,8,11
4,5,8,8,13
4,5,8,8,16
4,5,8,8,17
4,5,8,9,13
4,5,8,9,14
4,5,8,9,15
4,5,8,9,17
4,5,8,9,2
4,5,8,9,7
4,5,8,9,9
4,5,9,9,14
4,5,9,9,8
4,5,9,9,9
4,6,6,6,13
4,6,6,7,1
4,6,6,7,11
4,6,6,7,12
4,6,6,7,13
4,6,6,7,14
4,6,6,7,2
4,6,6,8,13
4,6,6,8,14
4,6,6,8,15
4,6,6,8,2
4,6,6,8,8
4,6,6,9,1
4,6,6,9,12
4,6,6,9,14
4,6,6,9,15
4,6,6,9,18
4,6,6,9,2
4,6,6,9,3
4,6,6,9,6
4,6,6,9,9
4,6,7,7,14
4,6,7,7,16
4,6,7,7,4
4,6,7,7,7
4,6,7,8,11
4,6,7,8,12
4,6,7,8,13
4,6,7,8,14
4,6,7,8,15
4,6,7,8,16
4,6,7,8,3
4,6,7,9,1
4,6,7,9,13
4,6,7,9,14
4,6,7,9,15
4,6,7,9,16
4,6,7,9,17
4,6,7,9,3
4,6,7,9,8
4,6,8,8,11
4,6,8,8,14
4,6,8,8,17
4,6,8,8,18
4,6,8,9,1
4,6,8,9,17
4,6,8,9,6
4,6,8,9,7
4,6,9,9,15
4,6,9,9,6
4,7,7,7,14
4,7,7,7,15
4,7,7,7,2
4,7,7,7,3
4,7,7,7,8
4,7,7,8,1
4,7,7,8,13
4,7,7,8,14
4,7,7,8,15
4,7,7,8,16
4,7,7,9,14
4,7,7,9,16
4,7,7,9,17
4,7,7,9,2
4,7,7,9,4
4,7,7,9,9
4,7,8,8,14
4,7,8,8,15
4,7,8,8,16
4,7,8,8,2
4,7,8,9,13
4,7,8,9,15
4,7,8,9,16
4,7,8,9,17
4,7,8,9,18
4,7,8,9,2
4,7,9,9,1
4,7,9,9,14
4,7,9,9,15
4,7,9,9,18
4,7,9,9,19
4,7,9,9,2
4,8,8,8,1
4,8,8,8,12
4,8,8,8,13
4,8,8,8,16
4,8,8,9,13
4,8,8,9,17
4,8,8,9,18
4,8,8,9,2
4,8,9,9,1
4,8,9,9,16
4,8,9,9,17
4,8,9,9,19
4,9,9,9,1
4,9,9,9,2
4,9,9,9,5
5,5,5,7,12
5,5,5,9,2
5,5,5,9,6
5,5,6,6,1
5,5,6,6,10
5,5,6,6,12
5,5,6,7,1
5,5,6,7,3
5,5,6,7,9
5,5,6,8,15
5,5,6,8,2
5,5,6,8,3
5,5,6,9,13
5,5,6,9,15
5,5,6,9,16
5,5,6,9,3
5,5,6,9,7
5,5,7,7,1
5,5,7,7,10
5,5,7,8,15
5,5,7,8,16
5,5,7,8,5
5,5,7,9,12
5,5,7,9,16
5,5,7,9,17
5,5,7,9,3
5,5,7,9,6
5,5,7,9,8
5,5,8,8,1
5,5,8,8,10
5,5,8,8,16
5,5,8,9,11
5,5,8,9,16
5,5,8,9,7
5,5,9,9,1
5,5,9,9,17
5,5,9,9,18
5,6,6,6,1
5,6,6,7,10
5,6,6,7,12
5,6,6,7,3
5,6,6,8,13
5,6,6,8,15
5,6,6,9,14
5,6,6,9,3
5,6,6,9,4
5,6,6,9,9
5,6,7,7,1
5,6,7,7,11
5,6,7,7,15
5,6,7,8,12
5,6,7,8,4
5,6,7,9,1
5,6,7,9,15
5,6,7,9,17
5,6,7,9,18
5,6,7,9,3
5,6,7,9,5
5,6,7,9,9
5,6,8,8,1
5,6,8,8,10
5,6,8,8,11
5,6,8,8,15
5,6,8,9,10
5,6,8,9,12
5,6,8,9,14
5,6,8,9,18
5,6,8,9,2
5,6,8,9,6
5,6,9,9,11
5,6,9,9,19
5,6,9,9,7
5,7,7,7,12
5,7,7,7,13
5,7,7,8,11
5,7,7,8,13
5,7,7,8,14
5,7,7,8,17
5,7,7,8,3
5,7,7,9,14
5,7,7,9,18
5,7,7,9,4
5,7,7,9,9
5,7,8,8,12
5,7,8,8,14
5,7,8,8,18
5,7,8,8,3
5,7,8,9,1
5,7,8,9,15
5,7,8,9,19
5,7,8,9,3
5,7,8,9,5
5,7,9,9,16
5,7,9,9,2
5,8,8,8,14
5,8,8,8,3
5,8,8,8,4
5,8,8,9,14
5,8,8,9,15
5,8,8,9,19
5,8,8,9,4
5,8,9,9,13
5,8,9,9,14
5,9,9,9,15
6,6,6,7,6
6,6,6,8,10
6,6,6,8,12
6,6,6,8,15
6,6,6,9,14
6,6,6,9,3
6,6,7,7,1
6,6,7,7,2
6,6,7,8,11
6,6,7,8,13
6,6,7,8,14
6,6,7,8,15
6,6,7,9,14
6,6,7,9,16
6,6,7,9,2
6,6,7,9,4
6,6,8,8,15
6,6,8,8,16
6,6,8,8,4
6,6,8,9,1
6,6,8,9,17
6,6,8,9,2
6,6,8,9,5
6,6,9,9,12
6,6,9,9,19
6,7,7,8,1
6,7,7,8,12
6,7,7,8,14
6,7,7,8,15
6,7,7,8,16
6,7,7,9,11
6,7,7,9,15
6,7,7,9,16
6,7,7,9,3
6,7,8,8,1
6,7,8,8,13
6,7,8,8,17
6,7,8,9,14
6,7,8,9,18
6,7,8,9,2
6,7,8,9,4
6,7,9,9,17
6,7,9,9,19
6,7,9,9,5
6,7,9,9,9
6,8,8,8,14
6,8,8,8,15
6,8,8,8,2
6,8,8,9,14
6,8,8,9,19
6,8,8,9,3
6,8,9,9,14
6,8,9,9,2
6,8,9,9,4
7,7,7,7,14
7,7,7,8,1
7,7,7,9,16
7,7,8,8,14
7,7,8,8,16
7,7,8,9,15
7,7,8,9,17
7,7,8,9,18
7,7,8,9,3
7,7,9,9,1
7,7,9,9,14
7,8,8,8,1
7,8,8,9,1
7,8,8,9,14
7,8,8,9,16
7,8,8,9,17
7,8,8,9,18
7,8,8,9,2
7,8,9,9,1
7,8,9,9,14
7,8,9,9,15
7,8,9,9,18
7,8,9,9,19
7,8,9,9,3
7,9,9,9,17
7,9,9,9,2
8,8,8,9,1
8,8,8,9,16
8,8,8,9,17
8,8,8,9,18
8,8,9,9,1
8,8,9,9,16
8,8,9,9,19
8,9,9,9,1
8,9,9,9,17
8,9,9,9,19
9,9,9,9,1`.split("\n");
export default playableRounds;
